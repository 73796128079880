// Root/Body
$root__text-color: #fff;
$root__font-size: $font-size-root;
$root__font-family: 'Montserrat', sans-serif;
$root__background-color: #0c0d0d;
$root__background-image: url('/static/media/bg.png');

// Navbar
$navbar__color: $navbar-dark-color;
$navbar__color-hover: $navbar-dark-hover-color;
$navbar__color-disabled: $navbar-dark-disabled-color;

// Inventory (Wrapped)
$inv-wrapped-item__height: 405px;
$inv-wrapped-item__width: 250px;

// Token logo
$token__background-from: #140022;
$token__background-into: #2f0c27;

.marketplace {
    text-align: center;

    .lootex-logo,
    .btn-trade {
        margin-bottom: 0.5rem;
    }

    .lootex-logo {
        height: 18rem;
        width: 18rem;

        background-image: url('/static/media/marketplace/lootex.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .btn-trade {
        font-size: 1.25rem;

        color: #fff;
        background-color: #df0091;

        padding: 0.15rem 2rem;
    }
}

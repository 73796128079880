.inventory {
    @extend .user-select-none;

    // p & span styles
    p {
        color: gray;

        span {
            color: #fff;
        }
    }

    .card-container {
        display: flex !important;
        justify-content: center;
    }

    // Card override
    .inv-card {
        @extend .card;

        border: 3px solid #fff;
        border-radius: 1.25rem;
    }

    .me-auto{
        margin: auto
    }
    // Wrapped component (Carousel)
    .inv-wrapped {
        position: relative;
        display: block;
        margin-bottom: 100px;

        .btn {
            padding: 0.25rem 1rem;
        }

        .card-image{
            display: flex;
            justify-content: center;
        }

        // Carousel
        .inv-winner {
            height: 100%;
            width: 100%;

            position: relative;
            display: flex;
            align-items: center;
            margin: 0 auto;

            .inv-witem {
                height: $inv-wrapped-item__height;
                width: $inv-wrapped-item__width;
                position: absolute;
                opacity: 0;

                //transition: all 0.3s ease-in-out;
                transform: translateX(-50%);

                &.prev,
                &.curr,
                &.next {
                    opacity: 1;
                    z-index: 1;
                }

                &.curr {
                    height: $inv-wrapped-item__height * 1.1;
                    width: $inv-wrapped-item__width * 1.1;
                    z-index: 2;

                    left: 50%;
                }

                &.prev {
                    left: 35%;
                }

                &.next {
                    left: 65%;
                }

                @include media-breakpoint-up(md) {
                    &.prev {
                        left: 17.5%;
                    }

                    &.next {
                        left: 82.5%;
                    }
                }
            }
        }

        .inv-wcontrols {
            position: absolute;
            display: flex;
            top: 50%;
            left: 0;
            right: 0;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            z-index: 3;

            .prev,
            .next {
                border: 0;
                cursor: pointer;
                background-color: transparent;
                color: #fff;
                padding: 0;

                font-size: 2.5rem;
                margin: 0.75rem;

                &:focus {
                    outline: none;
                }
            }

            @include media-breakpoint-up(lg) {
                //display: none;
                .prev,
                .next {
                    font-size: 1.25rem;
                    margin: 0;
                }
            }
        }
    }
    .connect-inventory{
        
            margin: auto;
            width: 50%;
            padding: 10px;
            justify-content: center;
            display: flex;
          
          
    }
    // Unwrapped component
    .inv-unwrapped {
        .btn {
            padding: 0.05rem 1rem;
        }

        .inv-ulist {
            .inv-urow {
                height: 85px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .id {
                    font-size: 1rem;
                    font-weight: bold;
                }

                .info {
                    color: gray;
                }

                .info,
                .btn {
                    font-size: 0.75rem;
                }
            }
        }
    }

    // Dashboard component
    .inv-dashboard {
        margin: 0 auto;

        .btn {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    .inv-unwrapped,
    .inv-dashboard {
        @extend .mx-auto;

        max-width: 95%;
        @include media-breakpoint-up(lg) {
            max-width: 70%;
        }
    }

    .form {
        &-select,
        &-control,
        .fake-input {
            color: #fff;
            border: 2px solid #fff;
            border-radius: 2rem;
            background-color: #000;

            padding: 0.5rem 1rem;
        }

        &-label {
            font-size: 1.5rem;
        }

        &-select {
            min-width: 40%;
        }

        &-control[type='text'] {
            min-width: 60%;
        }

        .invalid-address {
            border: 2px solid #dc3545;
            outline: none;
        }

        &-text {
            max-width: 60%;
            

            color: gray;
        }

        .fake-input {
            @extend .user-select-none;

            display: inline-block;
            min-width: 30%;
            text-align: center;
        }

        .btn {
            padding: 0.5rem 1.5rem;
        }
    }
}

// Y-axis snap scroll
.scroll-snap {
    overflow-y: scroll;

    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
        // Chrome, Safari and Opera
        display: none;
    }

    &.mandatory {
        scroll-snap-type: mandatory; // Old browsers
        scroll-snap-type: y mandatory;
    }

    //&.proximity {
    //    scroll-snap-type: proximity; // Old browsers
    //    scroll-snap-type: y proximity;
    //}

    & .scroll-step {
        scroll-snap-align: start;
    }
}

// Card
.card {
    background: linear-gradient(to top, #090909, #333);
    border-radius: 1rem;
    
    // Card content
    &-content {
        display: block;
        width: 100%;
        height: 100%;

        img {
            @extend .img-fluid;
            @extend .pe-none;

            border-radius: 1rem;
        }
    }
}

// Round btn
.btn {
    @extend .rounded-pill;
}

// VNY Token logo
//.token {
//    background: $token__background-from;
//    background: linear-gradient(0deg, rgba($token__background-from, 1) 0%, rgba($token__background-into, 1) 100%);
//    border-radius: 50%;
//    height: 30px;
//    width: 30px;
//    display: inline-block;
//    position: relative;
//
//    & > &-logo {
//        background-image: url("/static/media/logo.png");
//        background-size: contain;
//        background-position: center;
//        background-repeat: no-repeat;
//        background-origin: content-box;
//        position: absolute;
//        padding: .15rem;
//        top: 0;
//        left: 0;
//        width: 100%;
//        height: 100%;
//    }
//}

.navbar {
    @extend .user-select-none;

    // Home page fix
    margin: 0;
    @include media-breakpoint-up(md) {
        padding-top: 3rem;
        padding-bottom: 3rem;

        max-height: 15vh;
    }
    // ---

    .container {
        padding-bottom: 1.5rem;
    }

    &-toggler {
        border: none;

        &:focus {
            box-shadow: none;
        }
    }

    &-collapse {
        flex-grow: unset;
    }

    &-nav {
        & .nav-link {
            font-weight: 500;
        }

        @include media-breakpoint-up(md) {
            & .active {
                position: relative;
                text-align: center;

                &:after {
                    content: '';

                    border-bottom: solid thin $root__text-color;

                    width: 70%;

                    position: absolute;
                    bottom: 6px;
                    left: 15%;
                }
            }
        }
    }

    .vny-price {
        font-weight: 600;
    }

    .btn {
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

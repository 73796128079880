.home {
    div {
        span {
            display: inline-block;
            text-overflow: ellipsis;

            @include media-breakpoint-up(md) {
                max-width: 60%;
            }
        }

        .btn {
            color: #fff;

            padding-left: 2rem;
            padding-right: 2rem;

            margin-top: 1rem;
        }

        .btn-checkit {
            background-color: #0d6efd;
            margin-right: 0.5rem;
        }

        .btn-buyvny {
            border-color: #fff;
            margin-left: 0.5rem;
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    // Background
    background-image: url('/static/media/home/diamond-bg.png');
    background-size: 40rem;
    background-position: center;

    @include media-breakpoint-up(lg) {
        background-image: url('/static/media/home/diamond-bg.png'),
            url('/static/media/home/diamond.png');
        background-size: 40rem, 30rem;
        background-position: 90% 50%, 85% 60%;
    }

    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: contain;
}

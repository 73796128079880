// Info columns
.leaderboard-info {
    padding-bottom: 3rem;
    list-style-position: inside;

    h3 {
        font-weight: 600;

        @include media-breakpoint-down(md) {
            border-bottom: solid thin $root__text-color;
        }
    }

    li {
        font-size: 0.7rem;

        &::marker {
            font-size: 1rem;
            font-weight: 300;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1rem;
            margin-bottom: 0.5rem;

            &::marker {
                font-size: 1.75rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &:nth-of-type(odd) {
            border-right: solid thin $root__text-color;
            text-align: left;

            ol {
                padding-left: 1rem;
                direction: ltr;
            }
        }

        &:nth-of-type(even) {
            text-align: right;

            ol {
                padding-right: 1rem;
                direction: rtl;
            }
        }

        &:last-child,
        &:nth-last-child(2) {
            padding-bottom: 0 !important;
        }
    }
}

.buy {
    flex-grow: 1;

    // SubPage: Main
    .main {
        .bcard {
            @extend .user-select-none;

            height: 375px;
            width: 350px;

            @include media-breakpoint-up(md) {
                height: 375px;
                width: 450px;
            }

            border: 3px solid #fff;
            border-radius: 5rem;

            margin: 0 auto;

            position: relative;
            display: flex;
            text-align: center;

            &.bg {
                &-left,
                &-right {
                    background-image: url('/static/media/home/diamond-bg.png');
                    background-repeat: no-repeat;
                    background-attachment: local;
                }

                &-left {
                    background-position: bottom left;
                }

                &-right {
                    background-position: top right;
                }
            }

            .bcard-content {
                align-self: center;
                margin: auto;

                h1,
                h2 {
                    margin: 0;
                    padding: 0;
                }

                h1 {
                    font-size: 7.5rem;
                }
            }
        }

        .notice {
            max-width: 350px;

            margin: 0 auto;
            margin-top: 2rem;

            font-size: 0.75rem;
            text-align: center;
            color: gray;
        }
    }

    // Wrapped...
    // ...Unwrapped...
    // ...Form
    .form {
        &-select,
        &-control,
        .fake-input {
            color: #fff;
            border: 2px solid #fff;
            border-radius: 2rem;
            background-color: #000;

            padding: 0.5rem 1rem;
        }

        &-label {
            font-size: 1.5rem;
        }

        &-select {
            min-width: 40%;
        }

        &-control[type='text'] {
            min-width: 60%;
        }

        .invalid-address {
            border: 2px solid #dc3545;
            outline: none;
        }

        &-text {
            max-width: 60%;
            

            color: gray;
        }

        .fake-input {
            @extend .user-select-none;

            display: inline-block;
            min-width: 30%;
            text-align: center;
        }

        .btn {
            padding: 0.5rem 1.5rem;
        }
    }
}

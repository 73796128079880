.footer {
    @extend .user-select-none;

    // Leaderboard page fix
    padding-top: 3rem;
    padding-bottom: 3rem;

    max-height: 15vh;
    // ---

    &-link {
        font-size: 0.8rem;
        text-decoration: unset;
        color: $navbar__color;

        &:hover,
        &:focus {
            color: $navbar__color-hover;
        }

        &.disabled {
            color: $navbar__color-disabled;
        }
    }
}
